<button
  class="card-item border rounded w-100 h-100"
  type="button"
  (click)="getItem()"
>
  <div class="item-container rounded container position-relative gx-0">
    <div class="row">
      <div
        [class.col-12]="!menuItem.icon"
        [class.col-8]="menuItem.icon"
        class="user-select-none"
      >
        <div
          class="d-flex flex-column text-start justify-content-center p-3 text-dark"
        >
          <div
            class="fs-6 fw-bolder text-nowrap toe overflow-hidden mb-1 user-select-none"
          >
            {{ menuItem.name }}
          </div>
          <div
            class="mw-100 overflow-hidden toe item-description text-secondary user-select-none" style="line-height:1.3"
          >
            {{ menuItem.description }}
          </div>
          <div class="fw-500 mt-1 user-select-none">
            $

            <ng-container
              *ngIf="menuItem.menu_item_variants?.length; else priceTemplate"
            >
              {{ menuItem.menu_item_variants![0].price }}&nbsp;~&nbsp;{{
                menuItem.menu_item_variants![
                  menuItem.menu_item_variants!.length - 1
                ].price
              }}
            </ng-container>
            <ng-template #priceTemplate>{{ menuItem.price }} </ng-template>
          </div>
        </div>
      </div>
      <div class="col-4 h-100" *ngIf="menuItem.icon">
        <img src="{{ menuItem.icon }}" alt="{{ menuItem.name }}" />
      </div>
    </div>
    <div
      class="note-tag no-gutters p-1 rounded position-absolute user-select-none" style="color:brown;font-size:1.5rem"
    >
      <span
        class="note-text user-select-none" style="color:brown;font-size:1.4rem"
        *ngIf="menuItem.is_inventory"
        > Out Of Stock</span
      >
    </div>
  </div>
</button>
<ng-template #itemModal>
  <uo-item-modal></uo-item-modal>
</ng-template>
