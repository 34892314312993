import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { CheckoutService } from 'src/app/services/apis/checkout.service';
import { WindowService } from 'src/app/services/local/window.service';
import { storageKeys } from 'src/app/share/configs';
import {
  Checkout,
  DeliveryInfo,
  MinimumPay,
  OrderType,
  PaymentType,
  ShoppingCartItem,
  Tips,
} from 'src/app/share/types';
import { MessageService } from 'src/app/share/components/message/message.service';
import { ShoppingCartStoreModule } from 'src/app/state/shopping-cart/shopping-cart.store.module';
import { Store } from '@ngrx/store';
import { CustomTipsModalComponent } from '../modals/custom-tips-modal/custom-tips-modal.component';
import {
  setShoppingCart,
  setShoppingCartLength,
} from 'src/app/state/shopping-cart/action';
import { setPlacedOrder } from 'src/app/state/placed-order/action';
import { PlacedOrderStoreModule } from 'src/app/state/placed-order/placed-order.store.module';
import { AddCouponComponent } from '../modals/add-coupon/add-coupon.component';
import { PromotionService } from 'src/app/services/apis/promotion.service';
import { ModalDeleteComponent } from '../modals/delete/modal-delete/modal-delete.component';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'uo-check-out',
  templateUrl: './check-out.component.html',
  styleUrls: ['./check-out.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckOutComponent implements OnInit, AfterViewInit {
  loading: boolean = false;
  feeLoading: boolean = false;
  isCheckout: boolean = false;
  deliveryFeeRes!: DeliveryInfo;
  apiLoaded: boolean = false;
  isCustomTips: boolean = false;
  tipsId!: number;
  checkout!: Checkout;
  items!: ShoppingCartItem[];
  orderType!: OrderType;
  paymentType!: PaymentType;
  minimumPay!: MinimumPay;
  tipsAmount!: string;
  placeOrderBoxHeight!: number;
  @ViewChild('creditCardNumber') ccNumberField!: ElementRef;
  @ViewChild('expirationDate') expirationDateField!: ElementRef;
  @ViewChild('banners') bannersElement!: ElementRef;
  placeOrderForm = this.fb.group({
    store_id: ['', [Validators.required, Validators.minLength(1)]],
    order_type: [
      { value: '', disabled: this.feeLoading },
      [
        Validators.required,
        Validators.pattern('^[0-9]*$'),
        Validators.minLength(1),
      ],
    ],
    payment_type: [
      { value: '', disabled: this.feeLoading },
      [
        Validators.required,
        Validators.pattern('^[0-9]*$'),
        Validators.minLength(1),
      ],
    ],
    notes: [''],
    credit_card: this.fb.group({
      card_number: [
        '',
        [
          Validators.required,
          Validators.minLength(17),
          // Validators.maxLength(19)
        ],
      ],
      expiration_date: [
        '',
        [
          Validators.required,
          Validators.pattern('([0-9]{2}[/]?){2}'),
          Validators.minLength(5),
        ],
      ],
      name: ['', [Validators.required]],
      zipcode: ['', [Validators.required]],
      cvv: ['', [Validators.required]],
    }),
  });
  checkoutForm!: FormGroup;
  get store_id(): AbstractControl | null {
    return this.placeOrderForm.get('store_id');
  }
  get order_type(): AbstractControl | null {
    return this.placeOrderForm.get('order_type');
  }
  get payment_type(): AbstractControl | null {
    return this.placeOrderForm.get('payment_type');
  }
  get delivery_address(): AbstractControl | null {
    return this.placeOrderForm.get('delivery_address');
  }
  get credit_card(): FormGroup {
    return this.placeOrderForm.get('credit_card') as FormGroup;
  }
  get card_number(): AbstractControl | null {
    return this.credit_card.get('card_number');
  }
  get expiration_date(): AbstractControl | null {
    return this.credit_card.get('expiration_date');
  }
  get zipcode(): AbstractControl | null {
    return this.credit_card.get('zipcode');
  }
  get cvv(): AbstractControl | null {
    return this.credit_card.get('cvv');
  }
  get cardName(): AbstractControl | null {
    return this.credit_card.get('name');
  }
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private modalService: NgbModal,
    private winServe: WindowService,
    private messageServe: MessageService,
    private checkoutServe: CheckoutService,
    private shoppingCartStore$: Store<ShoppingCartStoreModule>,
    private placedOrderStore$: Store<PlacedOrderStoreModule>,
    private promoServe: PromotionService,
    private cdr: ChangeDetectorRef
  ) {}
  ngAfterViewInit(): void {
    this.getFixedBox();
  }
  ngOnInit(): void {
    this.payment_type?.patchValue(
      0
      // this.checkout.orderParams.paymentType.toString()
    );
    this.getCheckout();

    this.router.events.subscribe((event) => {
      if (!(event instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }

  getFixedBox(): void {
    const config = {
      attributes: true,
      childList: true,
      subtree: true,
    };
    const observer = new MutationObserver(() => {
      this.placeOrderBoxHeight = this.bannersElement.nativeElement.clientHeight;
      this.cdr.markForCheck();
    });
    observer.observe(this.bannersElement.nativeElement, config);
  }

  getCheckout(): void {
    this.loading = true;
    this.cdr.markForCheck();
    this.checkoutForm = this.fb.group({
      show_item: [1],
    });
    this.checkoutServe.getCheckout(this.checkoutForm.value).subscribe(
      (res) => {
        this.checkout = res.data;
        console.log('getCheckout :>> ', this.checkout);
        console.log('this.items :>> ', this.items);
        this.items = cloneDeep(res.data.cart);
        this.isCheckout = false;
        this.items.forEach((i) => {
          if (i.options.expired) {
            this.isCheckout = true;
            return;
          }
        });
        this.setCheckout();
        this.initTypes();
        if (this.items.length == 0) {
          this.router.navigate([
            'restaurant/' + this.checkout.orderParams.storeId,
          ]);
          this.messageServe.warning('No item founded!');
        }
        // console.log('this.checkout :>> ', this.checkout);
        this.loading = false;
        this.feeLoading = false;
        console.log('this.feeLoading :>> ', this.feeLoading);
        console.log('this.placeOrderForm :>> ', this.placeOrderForm);
        console.log('this.payment_type.value :>> ', this.payment_type?.value);
        this.cdr.detectChanges();
      },
      () => {
        this.endLoading();
      }
    );
  }
  setCheckout(): void {
    this.tipsAmount = this.checkout.tips;
    console.log('this.tipsAmount :>> ', this.tipsAmount);
    this.tipsId = this.checkout.orderParams.tips.id;
    if (!this.tipsId) {
      this.isCustomTips = true;
      this.tipsId = -1;
    }
    console.log('this.tipsId :>> ', this.tipsId);
    this.winServe.setLocalStorage(
      storageKeys.orderStatus,
      this.checkout.orderParams.orderType
    );
    this.winServe.setLocalStorage(
      storageKeys.payment,
      this.checkout.orderParams.paymentType
    );
    this.initTypes();
    if (this.checkout.store.minimum_pay) {
      this.minimumPay = JSON.parse(this.checkout.store.minimum_pay);
    } else {
      this.minimumPay = { 1: 0, 2: 0 };
    }
    console.log('this.minimumPay :>> ', this.minimumPay);
    this.orderType = JSON.parse(this.checkout.store.order_type);
    console.log('this.orderType :>> ', this.orderType);

    this.paymentType = JSON.parse(this.checkout.store.payment_type);
    this.paymentType[0] = true;
    this.winServe.setLocalStorage(
      storageKeys.storeInfo,
      JSON.stringify(this.checkout.store)
    );
    this.order_type?.patchValue(2);
    this.placeOrderForm
      .get('store_id')
      ?.setValue(this.checkout.orderParams.storeId);
    this.store_id?.patchValue(this.checkout.orderParams.storeId);
  }
  initTypes(): void {
    if (this.checkout.orderParams.orderType.toString() == '2') {
      this.tipsAmount = '0';
      this.isCustomTips = true;
    }
    if (
      this.payment_type?.value &&
      this.checkout.orderParams.paymentType.toString() == '1'
    ) {
      this.placeOrderForm.addControl(
        'credit_card',
        this.fb.group({
          card_number: [
            '',
            [
              Validators.required,
              Validators.minLength(17),
              // Validators.maxLength(19)
            ],
          ],
          expiration_date: [
            '',
            [
              Validators.required,
              Validators.pattern('([0-9]{2}[/]?){2}'),
              Validators.minLength(5),
            ],
          ],
          name: ['', [Validators.required]],
          zipcode: ['', [Validators.required]],
          cvv: ['', [Validators.required]],
        })
      );
    } else {
      this.placeOrderForm.removeControl('credit_card');
    }
  }
  minimumDeliveryCompare() {
    if (this.minimumPay[1]) {
      return parseFloat(this.checkout.subtotal) < this.minimumPay[1];
    }
    return false;
  }
  minimumPickupCompare() {
    if (this.minimumPay[2]) {
      return parseFloat(this.checkout.subtotal) < this.minimumPay[2];
    }
    return false;
  }
  payNone(e: Event): void {
    if ((e.target as HTMLInputElement).checked) {
      this.checkoutForm = this.fb.group({
        order_type: this.checkout.orderParams.orderType.toString(),
        payment_type: '0',
        tips_id: -1,
        tips_amount: 0,
      });
      // this.setTips();
      this.setOrderType();
      this.feeLoading = true;
      this.cdr.markForCheck();
      this.checkoutServe.getCheckout(this.checkoutForm.value).subscribe(
        (res) => {
          this.checkout = res.data;
          console.log('this.checkout :>> ', this.checkout);
          this.setCheckout();
          this.placeOrderForm.removeControl('credit_card');
          console.log(
            'this.placeOrderForm.value :>> ',
            this.placeOrderForm.value
          );
          this.endLoading();
        },
        () => {
          this.endLoading();
        }
      );
    }
  }
  payByCreditCard(e: Event): void {
    if ((e.target as HTMLInputElement).checked) {
      this.placeOrderForm.addControl(
        'credit_card',
        this.fb.group({
          card_number: [
            '',
            [
              Validators.required,
              // Validators.maxLength(19)
            ],
          ],
          expiration_date: [
            '',
            [
              Validators.required,
              Validators.pattern('([0-9]{2}[/]?){2}'),
              Validators.minLength(5),
            ],
          ],
          name: ['', [Validators.required]],
          zipcode: ['', [Validators.required]],
          cvv: ['', [Validators.required]],
        })
      );
      this.checkoutForm = this.fb.group({
        order_type: this.checkout.orderParams.orderType.toString(),
        payment_type: '1',
        delivery_address: [],
      });
      this.setTips();
      this.setOrderType();
      this.feeLoading = true;
      this.cdr.markForCheck();
      this.checkoutServe.getCheckout(this.checkoutForm.value).subscribe(
        (res) => {
          this.checkout = res.data;
          console.log('this.checkout :>> ', this.checkout);
          this.setCheckout();
          console.log(
            'this.placeOrderForm.value :>> ',
            this.placeOrderForm.value
          );
          this.endLoading();
        },
        () => {
          this.endLoading();
        }
      );
    }
  }
  setTips(): void {
    console.log('this.tipsId :>> ', this.tipsId);
    if (this.tipsId > 0) {
      this.checkoutForm.setControl(
        'tips_amount',
        this.fb.control(this.tipsAmount)
      );
      this.checkoutForm.setControl('tips_id', this.fb.control(-1));
    } else {
      this.checkoutForm.setControl('tips_id', this.fb.control(this.tipsId));
    }
  }
  payInStore(e: Event): void {
    if ((e.target as HTMLInputElement).checked) {
      this.checkoutForm = this.fb.group({
        order_type: this.checkout.orderParams.orderType.toString(),
        payment_type: '2',
        delivery_address: [],
        tips_id: -1,
        tips_amount: 0,
      });
      // this.setTips();
      this.setOrderType();
      this.feeLoading = true;
      this.cdr.markForCheck();
      this.checkoutServe.getCheckout(this.checkoutForm.value).subscribe(
        (res) => {
          this.checkout = res.data;
          console.log('this.checkout :>> ', this.checkout);
          this.setCheckout();
          this.placeOrderForm.removeControl('credit_card');
          console.log(
            'this.placeOrderForm.value :>> ',
            this.placeOrderForm.value
          );
          this.endLoading();
        },
        () => {
          this.endLoading();
        }
      );
    }
  }
  creditCardNumberSpacing() {
    const input = this.ccNumberField.nativeElement;
    const { selectionStart } = input;
    let trimmedCardNum = this.card_number!.value.replace(/\s+/g, '');
    console.log('trimmedCardNum :>> ', trimmedCardNum);
    if (trimmedCardNum.length > 16) {
      trimmedCardNum = trimmedCardNum.substr(0, 16);
    }
    /* Handle American Express 4-6-5 spacing */
    const partitions =
      trimmedCardNum.startsWith('34') || trimmedCardNum.startsWith('37')
        ? [4, 6, 5]
        : [4, 4, 4, 4];
    const numbers: number[] = [];
    let position = 0;
    partitions.forEach((partition) => {
      const part = trimmedCardNum.substr(position, partition);
      if (part) numbers.push(part);
      position += partition;
    });
    this.card_number!.setValue(numbers.join(' '));
    /* Handle caret position if user edits the number later */
    if (selectionStart < this.card_number!.value.length - 1) {
      input.setSelectionRange(selectionStart, selectionStart, 'none');
    }
  }
  expirationDateFormat() {
    const input = this.expirationDateField.nativeElement;
    const { selectionStart } = input;
    let trimmedExpirationDate = this.expiration_date!.value.replace(/\/+/g, '');
    console.log('trimmedExpirationDate :>> ', trimmedExpirationDate);
    if (trimmedExpirationDate.length > 4) {
      trimmedExpirationDate = trimmedExpirationDate.substr(0, 4);
    }
    const partitions = [2, 2];
    const numbers: number[] = [];
    let position = 0;
    partitions.forEach((partition) => {
      const part = trimmedExpirationDate.substr(position, partition);
      if (part) numbers.push(part);
      position += partition;
    });
    console.log('111 :>> ', 111);
    this.expiration_date!.setValue(numbers.join('/'));
    /* Handle caret position if user edits the number later */
    if (selectionStart < this.expiration_date!.value.length - 1) {
      input.setSelectionRange(selectionStart, selectionStart, 'none');
    }
  }
  selectTips(e: Event, tip: Tips): void {
    this.feeLoading = true;
    if ((e.target as HTMLInputElement).checked) {
      this.isCustomTips = false;
      this.checkoutForm = this.fb.group({
        order_type: this.checkout.orderParams.orderType,
        payment_type: this.checkout.orderParams.paymentType,
        delivery_address: [],
        tips_id: [tip.id],
        tips_amount: [-1],
      });
      console.log('selectTips :>> ', 'selectTips');
      this.setOrderType();
      console.log('this.checkoutForm.value :>> ', this.checkoutForm.value);
      this.cdr.markForCheck();
      this.checkoutServe.getCheckout(this.checkoutForm.value).subscribe(
        (res) => {
          this.checkout = res.data;
          console.log('this.checkout :>> ', this.checkout);
          this.setCheckout();
          this.endLoading();
        },
        (err) => {
          this.endLoading();
        }
      );
    } else {
      this.feeLoading = false;
      this.cdr.markForCheck();
    }
  }

  customTips(e: Event): void {
    const modalRef = this.modalService.open(CustomTipsModalComponent, {
      centered: true,
      scrollable: true,
    });
    modalRef.componentInstance.tipsAmount = this.checkout.tips;
    modalRef.closed.subscribe((res) => {
      this.isCustomTips = true;
      this.feeLoading = true;
      this.tipsId = -1;
      this.cdr.markForCheck();
      console.log('CustomTipsModalRes :>> ', res);
      this.checkoutForm = this.fb.group({
        order_type: this.checkout.orderParams.orderType,
        payment_type: this.checkout.orderParams.paymentType,
        delivery_address: [],
        tips_id: -1,
        tips_amount: -1,
      });
      if (res || res == 0) {
        this.checkoutForm.get('tips_amount')?.setValue(res);
      } else {
        this.checkoutForm
          .get('tips_amount')
          ?.setValue(parseFloat(this.tipsAmount) | 0);
      }
      console.log(
        'this.checkoutForm.get :>> ',
        this.checkoutForm.get('tips_amount')!.value
      );
      this.setOrderType();
      console.log('this.checkoutForm.value :>> ', this.checkoutForm.value);
      this.checkoutServe.getCheckout(this.checkoutForm.value).subscribe(
        (data) => {
          console.log('data :>> ', data);
          this.checkout = data.data;
          this.setCheckout();
          this.endLoading();
        },
        (err) => {
          this.endLoading();
        }
      );
    });
  }
  addCoupon(): void {
    const modalRef = this.modalService.open(AddCouponComponent, {
      centered: true,
      scrollable: true,
    });
    modalRef.closed.subscribe((res) => {
      console.log('addCoupon :>> ', res);
      const promoForm = this.fb.group({
        coupon_number: [res],
        merchant_id: [this.checkout.store.merchant_id],
        store_id: [this.checkout.store.id],
      });
      this.loading = true;
      this.cdr.markForCheck();
      this.promoServe
        .applyCoupon(
          promoForm.value,
          this.checkout.store.merchant_id.toString()
        )
        .subscribe(
          (data) => {
            if (data.data) {
              const value = data.data.value;
              if (value) {
                this.messageServe.warning(value);
              } else {
                this.checkout = data.data;
                this.messageServe.success('Coupon Added!');
              }
            }
            this.loading = false;
            this.cdr.markForCheck();
          },
          (err) => {
            this.messageServe.danger(err.error.message);
            this.loading = false;
            this.cdr.markForCheck();
          }
        );
    });
  }
  deleteCoupon(coupon: string): void {
    const modalRef = this.modalService.open(ModalDeleteComponent, {
      centered: true,
      scrollable: true,
    });
    modalRef.componentInstance.modalType = 21;
    modalRef.componentInstance.coupon = coupon;
    modalRef.componentInstance.merchantId = this.checkout.store.merchant_id;
    modalRef.closed.subscribe((res) => {
      if (res) {
        this.checkout = res;
        console.log('this.checkout :>> ', this.checkout);
        this.setCheckout();
        this.cdr.markForCheck();
      }
    });
  }
  navigateToRestaurant(): void {
    this.router.navigate([
      'restaurant/' + this.winServe.getLocalStorage(storageKeys.store),
    ]);
  }

  onDelete($event: Event): void {
    console.log('delete event :>> ', $event);
    $event.stopPropagation();
    // event.preventDefault();
  }
  bottomChange(e: Event): void {
    const element = e.target as HTMLElement;
    console.log('e.element:>> ', element);
  }
  setOrderType(): void {
    if (this.checkout.orderParams.orderType == '2') {
      this.checkoutForm.removeControl('delivery_address');
    }
  }
  endLoading(): void {
    this.loading = false;
    this.feeLoading = false;
    this.cdr.markForCheck();
  }
  onPlaceOrder(): void {
    this.loading = true;
    this.cdr.markForCheck();
    this.store_id?.patchValue(this.checkout.store.id);
    console.log('this.placeOrderForm.value:>> ', this.placeOrderForm.value);
    this.checkoutServe.placeOrder(this.placeOrderForm.value).subscribe(
      (res) => {
        console.log('res :>> ', res);
        if (res.data) {
          if (res.data.orderId) {
            this.messageServe.success('Order placed!~');
            this.shoppingCartStore$.dispatch(
              setShoppingCartLength({ length: 0 })
            );
            this.shoppingCartStore$.dispatch(
              setShoppingCart({
                cart: [],
              })
            );
            this.placedOrderStore$.dispatch(
              setPlacedOrder({
                placedOrder: res.data,
              })
            );
            this.loading = false;
            this.cdr.markForCheck();
            this.router.navigate([
              'order-placed',
              { orderId: res.data.orderId },
            ]);
          } else {
            this.loading = false;
            this.cdr.markForCheck();
          }
        } else {
          this.messageServe.danger('Failed! Please try again!');
          this.loading = false;
          this.cdr.markForCheck();
        }
      },
      () => {
        this.loading = false;
        this.cdr.markForCheck();
      }
    );
  }
}
